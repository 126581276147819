/** @format */

import { useNavigate } from 'react-router-dom';
import { Container } from './herosectionhome.style';

import LiveEditImageHighLighter from '../../../../liveEditHighlighterstyles/liveEditImageHighLighter.component';
import LiveEdithTextHighLighter from '../../../../liveEditHighlighterstyles/liveEdithTextHighLighter.component';
import {
  isImage,
  isVideo,
} from '../../../../utils/Helper_functions/VideoAndImage';
import FacebookVideoEmbed from '../../../utilComponent/FbEmbedVideo/FbEmbedVideo.utilComponents';
type HeroSectionProps = {
  data?: Record<string, any>;
};

export default function Herosectionhome({ data }: HeroSectionProps) {
  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate(`../idxlisting/`);
  };

  return (
    <Container
      id="sec1"
      $color={data.color}
      className="sec1">
      <div className="HomeContainer">
        <div className="overlay"></div>
        <div className="leftSection" style={{}}>
          <LiveEdithTextHighLighter
            section="sec1"
            type="text">
            <div
              className="Text"
              dangerouslySetInnerHTML={{ __html: data.text }}></div>
            {/* <h1>{data?.text}</h1> */}
          </LiveEdithTextHighLighter>
          <LiveEdithTextHighLighter
            section="sec1"
            type="description">
            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: data.description }}></div>
          </LiveEdithTextHighLighter>
          <button
            className="hero_button"
            onClick={() => handleRedirect()}>
            Search listings
            <svg
              width="10"
              height="20"
              viewBox="0 0 10 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.85092 0.586716C1.54312 0.586109 1.24482 0.693977 1.00782 0.891594C0.874422 1.00287 0.764157 1.13954 0.683335 1.29377C0.602513 1.44799 0.552722 1.61674 0.536814 1.79036C0.520906 1.96398 0.539193 2.13905 0.590628 2.30555C0.642064 2.47204 0.725635 2.62669 0.836559 2.76064L6.7383 9.86565L1.04734 16.9839C0.937909 17.1195 0.856192 17.2755 0.806881 17.443C0.75757 17.6105 0.741638 17.7861 0.760001 17.9598C0.778364 18.1335 0.830659 18.3018 0.913881 18.4551C0.997103 18.6084 1.10961 18.7436 1.24494 18.853C1.38124 18.9736 1.54086 19.0647 1.71378 19.1203C1.8867 19.176 2.06918 19.1951 2.24978 19.1764C2.43038 19.1577 2.6052 19.1017 2.76326 19.0118C2.92131 18.922 3.0592 18.8002 3.16827 18.6541L9.53109 10.7008C9.72484 10.4636 9.83077 10.1661 9.83077 9.85903C9.83077 9.55199 9.72484 9.25448 9.53109 9.01729L2.94432 1.06392C2.81217 0.903502 2.6443 0.776693 2.45432 0.693781C2.26435 0.610868 2.05762 0.574188 1.85092 0.586716Z"
                fill="black"
              />
            </svg>
          </button>
        </div>
        {/* Conditional Rendering for Image or Video */}
        {isImage(data?.image) ? (
          <img
            src={`/public/${data?.image}`}
            alt="HomeImage"
            loading="lazy"
            className="backgroundImage"
          />
        ) : isVideo(data?.image) ? (
          <video
            src={`/public/${data?.image}`}
            className="backgroundImage"
            autoPlay
            loop
            muted
            style={{ height: "95vh", minHeight: "56vw" }}
          />
        ) :
          (
            <iframe
              className="backgroundImage"
              src={`https://www.youtube.com/embed/${data?.image}?autoplay=1&mute=1&loop=1&playlist=${data?.image}&controls=0&rel=0&modestbranding=1&showinfo=0&iv_load_policy=3`}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{
                minWidth: "100%",
                objectFit: "cover",
                border: "none",
                aspectRatio: "16/9",
                height: "95vh",
                minHeight: "56vw",
              }} />)
        }
      </div>
    </Container>
  );
}


// <FacebookVideoEmbed videoUrl={'https://www.facebook.com/facebook/videos/10153231379946729/'} />


