
import { Container } from './imageredirector.style'

import { Link } from 'react-router-dom'

import usePermissionSoldProperty from '../../../CustomHooks/usePermissionSoldProperty.customHook'
import SoldHomeForm from '../../../utilComponent/SoldHomeForm/SoldHomeForm.component'
import HomeBrowser from './Images/t11.jpg'
import Ourteam from './Images/t12.jpg'
import SoldHome from './Images/t13.jpg'
export default function ImageRedirectContainer() {
    const { user,
        open,
        handleOpenClose,
        handleNavigation,
        handleSubmitForm,
        handleState,
    }=usePermissionSoldProperty()
    return (
        <Container>
            <Link to='../idxlisting' className="images">
                <p className='link' style={{fontFamily:"Tenor Sans, sans-serif"}}>Browse Homes</p>
                <img src={HomeBrowser} alt="presentation" />
            </Link>
            <Link to={!user ? '' : '../soldlisting'} onClick={handleNavigation} className="images">
                <p className='link' style={{fontFamily:"Tenor Sans, sans-serif"}} >Sold Homes</p>
                <img src={SoldHome} alt="presentation" />
            </Link>
            <Link to='../builderdeals' className="images">
                <p className='link' style={{fontFamily:"Tenor Sans, sans-serif"}}>Builder Deals</p>
                <img src={Ourteam} alt="presentation" />
            </Link>
            {open && <SoldHomeForm handleOpenClose={handleOpenClose} handleState={handleState} handleSubmitForm={handleSubmitForm}/>}
        </Container>
    )
}
