import { styled } from "styled-components";
import { MainContainer, InputContainer } from "../profile/pofile.style";
export const TempaltecardContainer = styled.div`

`

export const TemplateswitchContainer = styled(MainContainer)`
  width:100%,
`

export const TemmplateSwitcherInputContainer = styled(InputContainer)`
   align-items: center;
   justify-content: center;
   width: 100%;
`
