import axios from 'axios'
import React, { SetStateAction } from 'react'
import { convertToHTML } from 'draft-convert';
import { EditorState } from 'draft-js';
import Cookies from 'js-cookie'

type EmailReminderProps = {
  type: 'buyers' | 'sellers' | null
  setEmailMessage: React.Dispatch<SetStateAction<string>>
}

const api = {
  'buyers': '/api/send-buyer-reminder',
  'sellers': '/api/send-seller-reminder'
}
export default function useEmailRemider({ type, setEmailMessage }: EmailReminderProps) {
  const SetRemider = async (TextData: EditorState, email: string, reminder: string): Promise<void> => {
    const token = Cookies.get('token');
    const ConvertHtml = convertToHTML(TextData.getCurrentContent());
    try {
      const data = await axios.post(api[type],
        {
          "email": email,
          "reminderMessage": ConvertHtml,
          "reminderInterval": reminder
        },
        {
          headers: {
            'x-access-token': token
          }
        }
      )
      setEmailMessage(() => data.data.message)
    } catch (e) {
      setEmailMessage(() => "You need to select the days to send reminder emails.")
      throw new Error(e)
    }
  }

  return {
    SetRemider
  }
}

