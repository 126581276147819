import { styled } from 'styled-components';

export const Heading = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:center;
  margin-top: 2rem;
  margin-bottom: 1rem;
  padding-inline:1rem;
  h1 {
      color: var(--text-color);
      font-family: "Tenor Sans", sans-serif;
      font-size: 3rem;
      font-size: clamp(1.5rem, 1rem + 2vw, 3rem);
      font-style: normal;
      font-weight: bold;
      line-height: normal;
      text-transform: uppercase;
    }
    p {
      color: var(--text-color);
      font-family: '"Tenor Sans", sans-serif';
      font-size: 1.125rem;
      font-size: clamp(0.75rem, 1rem + 1vw, 1.125rem);
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      text-transform: capitalize;
    }
  .button {
    background-color: ${({ theme: { colors } }) => colors.primary};
    padding: 1rem 2rem;
    border-radius: 0.625rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    p {
      color: ${({ theme: { colors } }) => colors.white};
      font-family: "Tenor Sans", sans-serif;
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
      margin: 0;
    }
    &:hover {
      border: 1px solid ${({ theme: { colors } }) => colors.primary};
      p {
        color: ${({ theme: { colors } }) => colors.primary};
      }
      background-color: white;
      svg {
        path {
          fill: ${({ theme: { colors } }) => colors.primary};
        }
      }
    }
  }
`;
