/** @format */

import styled from "styled-components";

export const Container = styled.div`
  width: min(85rem, 97%);
  padding: 1rem 3rem;
  margin: 0 auto;
  /* display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr)); */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
  .images {
    position: relative;
    flex: 1;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 300px;
    img {
      width: 100%;
      height: 100%;
      /* height: 300px; */
      height: 100%;
      object-fit: cover;
    }

    .link {
  font-family: "Tenor Sans", sans-serif;
      position: absolute;
      color: white;
      font-size: clamp(1rem, 2vw, 2.125rem);
      text-decoration: none;
      font-weight: 500;
      z-index: 1;
      transition: all .3s;
    }
    &::before {
      position: absolute;
      content: "";
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      opacity: 0.3;
      background-color: black;
    }
  }
  .images:hover {
    background-color: black;
    img {
      -webkit-transform: scale(1.05);
      transform: scale(1.05);
      -webkit-transition: -webkit-transform .75s ease-out;
      transition: transform .75s ease-out;
      opacity: 0.5;
    }
    .link {
      -webkit-transform: translateY(-50px);
      transform: translateY(-50px);
    }
  }
  @media screen and (max-width: 605px) {
    flex-direction: column;
  }
`;

export const PopupFormContainer = styled.div`
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.4)
  );
  display: flex;
  justify-content: center;
  align-items: center;
`;
type PopupFormsProps = {
  $disabledCheck: boolean;
};

export const PopupForm = styled.form<PopupFormsProps>`
  width: min(25rem, 95%);
  background-color: white;
  padding: 1rem;
  border-radius: 1rem;
  h3 {
    text-align: center;
  }
  .warning {
    text-align: center;
    color: red;
  }
  .Input {
    display: block;
    width: 100%;
    padding: 0.3rem 0.8rem;
    margin-bottom: 1rem;
    border-radius: 0.25rem;
    border: 1px solid black;
    resize: none;
  }
  .TextArea {
    height: 9rem;
  }
  .CheckBox {
    display: flex;
    align-items: baseline;
    gap: 0.8rem;
    margin-bottom: 0.8rem;
    p {
      color: ${(props) =>
        props.theme?.colors?.primary
          ? props.theme?.colors?.primary
          : "var(--primary)"};
    }
  }
  .buttons {
    display: flex;
    gap: 1rem;
    .button {
      border: none;
      width: 100%;
      background-color: ${(props) =>
        props.theme?.colors?.primary
          ? props.theme?.colors?.primary
          : "var(--primary)"};
      color: white;
      border-radius: 0.4rem;
      padding: 0.45rem;
      &.submit_button {
        cursor: ${(props) =>
          props.$disabledCheck ? "not-allowed" : "pointer"};
      }
    }
  }
`;
