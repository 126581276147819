/** @format */

import React, { useState } from 'react';
import Editors from '../Editor/Editor';
import './BlogsHeader.css';
export default function BlogsHeader({
  fetchData,
  setSearchTerm,
  Search,
  setSort,
}) {
  const [search, setSearch] = useState('idle');
  const [editor, setEditor] = useState('idle');
  const handleSearch = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    event.stopPropagation();
    if (search === 'idle') {
      setSearch('search');
    } else {
      setSearch('idle');
    }
  };

  //search button
  const handleSearChange = (
    event: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    // event.stopPropagation();
    Search();
  };
  //handle Input change
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      fetchData();
    }
    setSearchTerm(event.target.value);
  };

  //handle sort
  const handleSort = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSort(event.target.value);
  };
  return (
    <>
      <nav
        className="d-flex justify-content-between align-items-center"
        onClick={() => setSearch('idle')}>
        <p className="header" style={{fontFamily:" Tenor Sans, sans-serif"}} >Blogs</p>
        <div className="blogs_actions d-flex gap-5 ">
          <div
            className="create d-flex align-items-center gap-1"
            onClick={() => setEditor('edit')}>
            <div className="wrapper">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none">
                <path
                  d="M21.5622 7.29183C21.9788 6.87516 21.9788 6.25016 21.5622 5.8335L19.1663 3.43766C18.7497 3.021 18.1247 3.021 17.708 3.43766L16.5401 4.60556C16.1496 4.99608 16.1496 5.62925 16.5401 6.01977L19.7913 9.271M3.41757 17.6239C3.23003 17.8115 3.12467 18.0658 3.12467 18.331V20.8752C3.12467 21.4274 3.57239 21.8752 4.12467 21.8752H6.66614C6.93293 21.8752 7.18865 21.7686 7.37644 21.5791L17.8499 11.0104C18.2338 10.623 18.2365 9.99958 17.8561 9.60887L15.3942 7.08042C15.0052 6.68098 14.3648 6.67672 13.9706 7.07093L3.41757 17.6239ZM6.79134 2.0835C7.06748 2.0835 7.29134 2.30735 7.29134 2.5835V4.7085C7.29134 4.98464 7.5152 5.2085 7.79134 5.2085H9.91634C10.1925 5.2085 10.4163 5.43235 10.4163 5.7085V6.79183C10.4163 7.06797 10.1925 7.29183 9.91634 7.29183H7.79134C7.5152 7.29183 7.29134 7.51569 7.29134 7.79183V9.91683C7.29134 10.193 7.06748 10.4168 6.79134 10.4168H5.70801C5.43187 10.4168 5.20801 10.193 5.20801 9.91683V7.79183C5.20801 7.51569 4.98415 7.29183 4.70801 7.29183H2.58301C2.30687 7.29183 2.08301 7.06797 2.08301 6.79183V5.7085C2.08301 5.43235 2.30687 5.2085 2.58301 5.2085H4.70801C4.98415 5.2085 5.20801 4.98464 5.20801 4.7085V2.5835C5.20801 2.30735 5.43187 2.0835 5.70801 2.0835H6.79134Z"
                  fill="#FAFAFC"
                />
              </svg>
            </div>
            <p className='sub_texts'>Create a new post</p>
          </div>
          <div
            className="search d-flex align-items-center gap-1"
            onClick={handleSearch}>
            {search === 'search' && (
              <input
                type="text"
                placeholder="SEARCH"
                className="search_input"
                onChange={handleInputChange}
                onClick={(event) => event.stopPropagation()}
              />
            )}
            <div
              className="wrapper"
              onClick={handleSearChange}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none">
                <path
                  d="M16.1454 14.5834H15.3225L15.0308 14.3021C15.6818 13.5458 16.1576 12.655 16.4241 11.6934C16.6906 10.7318 16.7413 9.72311 16.5725 8.73962C16.0829 5.84379 13.6662 3.53128 10.7495 3.17712C9.72415 3.04739 8.68266 3.15396 7.70478 3.48867C6.7269 3.82337 5.83855 4.37735 5.1077 5.10819C4.37686 5.83904 3.82289 6.72739 3.48818 7.70527C3.15348 8.68315 3.04691 9.72463 3.17663 10.75C3.5308 13.6667 5.8433 16.0834 8.73913 16.573C9.72262 16.7418 10.7313 16.6911 11.6929 16.4246C12.6545 16.158 13.5453 15.6823 14.3016 15.0313L14.5829 15.323V16.1459L19.01 20.573C19.437 21 20.135 21 20.562 20.573C20.9891 20.1459 20.9891 19.448 20.562 19.0209L16.1454 14.5834ZM9.89538 14.5834C7.30163 14.5834 5.20788 12.4896 5.20788 9.89587C5.20788 7.30212 7.30163 5.20837 9.89538 5.20837C12.4891 5.20837 14.5829 7.30212 14.5829 9.89587C14.5829 12.4896 12.4891 14.5834 9.89538 14.5834Z"
                  fill="#FAFAFC"
                />
              </svg>
            </div>

            {search === 'idle' && <p className='sub_texts'>SEARCH</p>}
          </div>
        </div>
        <div className="sortContainer d-flex align-items-center gap-2 ">
          <div className="wrapper">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none">
              <path
                d="M3.125 17.75C3.125 18.3023 3.57272 18.75 4.125 18.75H8.375C8.92728 18.75 9.375 18.3023 9.375 17.75V17.6667C9.375 17.1144 8.92728 16.6667 8.375 16.6667H4.125C3.57272 16.6667 3.125 17.1144 3.125 17.6667V17.75ZM4.125 6.25C3.57272 6.25 3.125 6.69772 3.125 7.25V7.33333C3.125 7.88562 3.57272 8.33333 4.125 8.33333H20.875C21.4273 8.33333 21.875 7.88562 21.875 7.33333V7.25C21.875 6.69772 21.4273 6.25 20.875 6.25H4.125ZM3.125 12.5417C3.125 13.094 3.57272 13.5417 4.125 13.5417H14.625C15.1773 13.5417 15.625 13.094 15.625 12.5417V12.4583C15.625 11.906 15.1773 11.4583 14.625 11.4583H4.125C3.57272 11.4583 3.125 11.906 3.125 12.4583V12.5417Z"
                fill="#999999"
              />
            </svg>
          </div>
          <p className='sub_texts'>Sort By</p>
          <select onChange={handleSort}>
            <option value="dsc">Highest Views</option>
            <option value="asc">Lowest Views</option>
          </select>
        </div>
      </nav>
      {editor === 'edit' && (
        <div
          className="editor"
        >
          <Editors
            setEditor={setEditor}
            header={'create'}
            _id={null}
            fetchData={fetchData}
          />
        </div>
      )}
    </>
  );
}
