import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { MainContainer, InputContainer } from "../profile/pofile.style";
import { TemplateswitchContainer, TemmplateSwitcherInputContainer } from "./templateswitching.style";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import Template1Image from '../../../WebsiteDesigner/images/Template 1 - Desktop - Homepage- Color 1.png'
import Template2Image from '../../../WebsiteDesigner/images/Template 2 - Desktop - Homepage- Color 6.png'
import Template3Image from '../../../WebsiteDesigner/images/template3_blue.png'
import Toggle from "./Toggle";
type ProfileProps = {
  _id: string;
  activetempid: string;
  domain: string;
};

const LargeRadioLabel = styled.label`
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-size: 18px;
`;

const LargeRadioButton = styled.input`
  width: 24px;
  height: 24px; 
  margin-right: 10px; 
`;

export const TemplateSwitchingDashboard = () => {
  const token = Cookies.get("token");
  const navigate = useNavigate();
  const [profile, setProfile] = useState<ProfileProps>({
    _id: "",
    activetempid: "",
    domain: "",
  });
  const [notification, setNotification] = useState<string>("");
  const [showBackButton, setShowBackButton] = useState<boolean>(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [type, setType] = useState<'editor' | 'publish'>('editor');
  const [activeTemplate, setActivtemplate] = useState<{
    editor: number | null,
    publish: number | null,
  }>({
    editor: null,
    publish: null
  });
  const images = [Template1Image, Template2Image, Template3Image]
  useEffect(() => {
    const fetchProfile = async (): Promise<void> => {
      try {
        const profileData = await axios.get("/api/get-agent-profile", {
          headers: {
            "x-access-token": token,
          },
        });
        const FetchedProfile = profileData.data.data
        setProfile(FetchedProfile);
        if (FetchedProfile.domain && FetchedProfile.activetempid) {
          setActivtemplate((prev) => ({ ...prev, publish: Number(FetchedProfile.activetempid) }))
        }
      } catch (e) {
        console.error("Error fetching profile:", e);
      }
    };
    fetchProfile();
  }, [type]);

  useEffect(() => {
    const fetchEditorId = async (): Promise<void> => {
      try {
        const token = Cookies.get('token');
        const fetchTemplate = await axios.get('/api/get-active-template', {
          headers: {
            'x-access-token': token,
          },
        });
        const TemplateId = fetchTemplate.data.data.activetemplate
        console.log(TemplateId)
        setActivtemplate((prev) => ({ ...prev, editor: Number(TemplateId) }))
      } catch (e) {
        throw new (e)
      }
    }
    fetchEditorId()
  }, [type])
  const handleTemplateChange = (e: React.ChangeEvent<HTMLInputElement> | string) => {
    let templateId: string | null = null;

    if (typeof e !== "string") {
      templateId = e.target?.value;
    } else {
      templateId = e;
    }
    console.log(type, templateId)
    setActivtemplate((prev) => ({ ...prev, [type]: Number(templateId) }))
  };
  console.log(activeTemplate)
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    console.log(type)
    try {
      if (type === 'editor') {
        await axios.post(
          `/api/save-active-template`,
          { activetemplate: activeTemplate.editor },
          {
            headers: {
              "x-access-token": token,
            },
          }
        );
      } else {
        await axios.post(
          `/admin/update-agent/${profile._id}`,
          { activetempid: activeTemplate.publish },
          {
            headers: {
              "x-access-token": token,
            },
          }
        );
      }

      setIsUpdated(true);
      setNotification("Template updated successfully!");
    } catch (e) {
      console.error("Error updating template:", e);
      setNotification("Error updating the template. Please try again.");
      setShowBackButton(true);
    }
  };

  const handleBackButtonClick = () => {
    setNotification("");
    setShowBackButton(false);
    setIsUpdated(false);
  };

  const handleProceed = () => {
    navigate(`/designer/${activeTemplate[type]}/colorselector`);
  };

  return (
    <TemplateswitchContainer >
      {isUpdated || showBackButton ? (
        <div className="d-flex justify-content-center align-items-center flex-column h-100">
          <h3>{notification}</h3>
          {isUpdated && (
            <button className="btn btn-dark" style={{ margin: '5px' }} onClick={handleProceed}>Continue Editing Template {activeTemplate[type]}</button>
          )}
          <button className="btn btn-dark" onClick={handleBackButtonClick}>Back</button>
          <br />
          <br />
          <label style={{ color: "white" }}>
            Note: After updating, clear your browser history and cached images and files. Then refresh the domain to see the changes.
          </label>
        </div>
      ) : (
        <>
          <h3>Switch Template</h3>
          <br />
          {notification && <p style={{ color: "red" }}>{notification}</p>}
          <Toggle canPublish={profile.domain} onChange={(option: 'editor' | 'publish') => {
            setType(option)
          }} />
          <form onSubmit={handleSubmit} className="d-fex align-items-center justify-content-center">
            <TemmplateSwitcherInputContainer>
              <label>Select the template you want to use </label>
              <div className="d-flex align-items-center justify-content-center flex-wrap gap-3">
                {[1, 2, 3].map((id, index) => (
                  <LargeRadioLabel key={id} className={`d-fex flex-column ${activeTemplate[type] === id ? "border border-white p-3" : ""} `} onClick={() => handleTemplateChange(id.toString())} >
                    <LargeRadioButton
                      type="radio"
                      name="activetempid"
                      value={id}
                      checked={activeTemplate[type] === id}
                      onChange={handleTemplateChange}
                    />
                    Template {id}
                    {/* <img src={`Template${index + 1}Image`} /> */}
                    <img src={images[index]} />
                  </LargeRadioLabel>
                ))}
              </div>
            </TemmplateSwitcherInputContainer>
            <div className="d-flex justify-content-center align-items-center">
              <button type="submit" className="btn btn-dark">Update</button>
            </div>
            <br />
            <br />
            <label style={{ color: "white" }}>
              Note: After updating, clear your browser history and cached images and files. Then refresh the domain to see the changes.
            </label>
            <br />
          </form>
        </>
      )
      }
    </TemplateswitchContainer >
  );
};
