/** @format */

import {
  useEffect,
  useState,
} from 'react';

import axios from 'axios';
import Cookies from 'js-cookie';
import {
  useLocation,
  useParams,
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { useMessageContext } from '../../../context/Message.context';
import LiveEdithTextHighLighter
  from '../../../liveEditHighlighterstyles/liveEdithTextHighLighter.component';
import GetTemplateData from '../../../utils/apis/GetTemplateData.api';
import { HelmetSEO } from '../../../utils/HelmetSEO/HelmetSEO.utilcomponent';
import SiteBuilder from '../../SiteBuilder/SiteBuilder';
import BlogSlider
  from '../../Template3/Components/blog_slider/blog_slider.component';
import AboutInfo from '../components/aboutInfo/aboutInfo.t2';
import Aboutushome from '../components/aboutushome/aboutushome.t2';
import Footer from '../components/footer/footer.t2';
import Navbar from '../components/navbar/navbar.t2';
import OurTeam from '../components/ourteam/ourteam.t2';
import { OurTeamContainer } from './about.style';

interface Seolisting {
  _id: string;
  title: string;
  keywords: string;
  description: string;
  url: string;
}

export default function Aboutus({ templateData }) {
  const [data, setData] = useState(null);
  const [temp1, setTemp1] = useState(null);
  const [profile, setProfile] = useState(null);
  const [Seo, setSeo] = useState<Seolisting[]>([]);
  const [seoHomeData, setSeoHomeData] = useState<Seolisting | null>(null);
  const location = useLocation();
  const { preview } = useParams();
  const token = Cookies.get("token");

  const getQueryParams = () => {
    return new URLSearchParams(location.search);
  };
  const { setTheme } = useMessageContext();
  const params = getQueryParams();

  const siteBuilder = params.get("siteBuilder");

  useEffect(() => {
    if (templateData) {
      setData(templateData);
    }
  }, [templateData]);

  useEffect(() => {
    const getData = async () => {
      if (!templateData) {
        const data = await GetTemplateData(preview, 2, 2);
        if (data) {
          setData(data);
          setTheme({
            primary_color: data.styles[0].css.primary,
            secondary_color: data.styles[0].css.secondary,
            warning_message: {
              fontFamily: "Tenor Sans, sans-serif",
              fontSize: "20px",
              fontWeight: "400",
              lineHeight: "23.4px",
            },
            warning_text: {
              fontFamily: "Libre Baskerville",
              fontSize: "18px",
              fontWeight: "400",
              lineHeight: "22.32px",
              textAlign: "center",
            },
            button: {
              fontFamily: "Tenor Sans, sans-serif",
              fontSize: "18px",
              fontWeight: "400",
              lineHeight: "23.4px",
            },
          });
        }
      } else {
        setData(templateData);
      }
    };
    getData();
  }, []);
  useEffect(() => {
    const getData = async () => {
      const data = await GetTemplateData(preview, 2, 1);
      if (data) {
        setTemp1(data);
      }
    };
    getData();
  }, []);

  useEffect(() => {
    const fetchProfile = async (): Promise<void> => {
      const agentUrl = window.location.href.split("/")[2];
      let config = {
        headers: {},
        params: {},
      };
      if (token) {
        config.headers["x-access-token"] = token;
      } else {
        config.params["domain"] = agentUrl;
      }
      try {
        const profileData = await axios.get("/api/get-agent-profile", config);
        setProfile(profileData.data.data);
      } catch (e) {
        throw new Error(e);
      }
    };
    fetchProfile();
  }, []);

  useEffect(() => {
    const FetchSeo = async () => {
      const token = Cookies.get("token");
      const agentUrl = window.location.href.split("/")[2];
      let config = {
        headers: {},
        params: {},
      };

      if (token) {
        config.headers["x-access-token"] = token;
      } else {
        config.params["domain"] = agentUrl;
      }

      try {
        const fetchData = await axios.get("/api/get-all-seo-entries", config);
        const fetchedData = fetchData.data.data;
        const homeSeoEntry = fetchedData.find((entry) =>
          entry.url.includes("about")
        );
        setSeoHomeData(homeSeoEntry || null);
      } catch (e) {
        console.error("Error fetching seo listings:", e);
      }
    };

    FetchSeo();
  }, []);
  const defaultDescription = `Detailed REALTOR® profile for ${
    profile?.name || "about Agent"
  } , real estate agent at ROYAL STAR REALTY INC.`;
  const defaultKeywords =
    "real estate listings, homes for sale, homes for rent, property listings";

  const scrollToDiv = () => {
    const targetDiv = document.getElementById("sec4");
    if (targetDiv) {
      window.scrollTo({
        top: targetDiv.offsetTop,
        behavior: "smooth",
      });
    }
  };
  if (params.get("scroll") === "true") {
    scrollToDiv();
  }

  if (!data)
    return (
      <div id="loadingScreen" className="loading-screen">
        <div className="loader"></div>
      </div>
    );

  const theme = {
    colors: {
      primary: data.styles[0].css.primary,
      text: data.styles[0].css.secondary,
      text2: "#000000",
      background: "#f8f9fa",
    },
    // add more root values as needed
  };
  // const customStyles = `
  //       @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap')
  //   `;

  const content = (
    <>
      <HelmetSEO
        seoHomeData={seoHomeData}
        profile={profile}
        defaultDescription={defaultDescription}
        defaultKeywords={defaultKeywords}
      />
      {/* <style>{customStyles}</style> */}
      <ThemeProvider theme={theme}>
        <Navbar data={temp1?.header} />
        {data.sec1.visible && <Aboutushome data={data.sec1} />}
        <AboutInfo data={data} />
        {data.sec4.visible && (
          <OurTeamContainer>
            <h1>
              <LiveEdithTextHighLighter section={"sec4"} type="text">
                {data?.sec4.text}
              </LiveEdithTextHighLighter>
            </h1>
            <OurTeam data={data.sec4.card} section={"sec4"} />
          </OurTeamContainer>
        )}
        {/* <NewProject id="sec5">
          <h1>{data.sec5.text}</h1>
          <Logos data={temp1?.sec6} />
        </NewProject> */}

        <div
          className="sec9"
          style={{
            position: "relative",
            backgroundSize: "cover",
            backgroundAttachment: "fixed",
            backgroundImage: `url(${"/public/" + data.sec7.image})`,
            minHeight: "400px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundPosition: "center"
          }}
          id="sec5"
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              zIndex: 1,
            }}
          ></div>

          <div
            className="container"
            style={{
              position: "relative",
              zIndex: 2,
              padding: "0 20px",
              textAlign: "center",
            }}
          >
            <h2
              style={{
                fontFamily: "Tenor Sans, sans-serif",
                fontWeight:"bold",
                marginTop:"1rem",
                textTransform: "uppercase",
                marginBottom: "1rem",
                textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)",
                color: data.sec7.color,
              }}
            >
              <LiveEdithTextHighLighter section={"sec7"} type="text">
                {" "}
                {data.sec7.text}
              </LiveEdithTextHighLighter>
            </h2>
            <p
              style={{
                fontFamily: "Montserrat,sans-serif",
                marginBottom: "1.5rem",
                textShadow: "1px 1px 3px rgba(0, 0, 0, 0.5)",
                color: data.sec7.color,
              }}
            >
              <LiveEdithTextHighLighter section={"sec7"} type="description">
                {data.sec7.description}
              </LiveEdithTextHighLighter>
            </p>
            <a
              href={data.sec7?.action?.link || "/blue2/contact"}
              className="btn btn-outline-light"
            >
              {data.sec7?.action?.text || "Contact Us"}
            </a>
          </div>
        </div>
        <BlogSlider />

        <Footer data={temp1} />
      </ThemeProvider>
    </>
  );

  return (
    <SiteBuilder
      {...{
        template: content,
        siteBuilder: siteBuilder,
        data,
        setData,
      }}
    ></SiteBuilder>
  );
}
