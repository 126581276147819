import { useState } from "react";

const PEXELS_API_KEY = "HsmciKtcQTUwyVM3ll0q5AsSlqLHn2qV3zKcni5bOAaDPxLhJ29WZ4bW";
const PEXELS_API_URL = "https://api.pexels.com/v1/search?query=";

const ImageSearch = () => {
  const [query, setQuery] = useState("");
  const [images, setImages] = useState([]);

  const searchImages = async () => {
    if (!query.trim()) return;
    
    const response = await fetch(`${PEXELS_API_URL}${query}&per_page=12`, {
      headers: { Authorization: PEXELS_API_KEY },
    });
    const data = await response.json();
    setImages(data.photos || []);
  };

  const downloadImage = (url, filename) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="p-4 text-center bg-dark text-white">
      <h2>Search Images</h2>
      <input
        type="text"
        placeholder="Search images..."
        className="border p-2 rounded-md mx-2"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      <button onClick={searchImages} className="bg-blue-500 text-dark p-2 rounded-md">
        Search
      </button>
      <div className="grid grid-cols-3 gap-6 mt-4">
        {images.map((photo) => (
          <img style={{padding:"10px"}}
            key={photo.id}
            src={photo.src.medium}
            alt={photo.photographer}
            className="rounded-md shadow-md cursor-pointer"
            onClick={() => downloadImage(photo.src.original, `pexels-${photo.id}.jpg`)}
          />
        ))}
      </div>
    </div>
  );
};

export default ImageSearch;
