import React, { useState } from 'react';
import { motion } from 'framer-motion';

const Toggle = ({ onChange, canPublish }) => {
  const [selected, setSelected] = useState<'editor' | 'publish'>('editor'); // Default state: 'local'

  const handleToggle = (option: 'editor' | 'publish') => {
    if (option === 'publish' && !canPublish) {
      alert('Cannot switch to Publish. Conditions not met.');
      return;
    }
    setSelected(option);
    if (onChange) onChange(option); // Trigger callback if provided
  };

  return (
    <div className="d-flex align-items-center justify-content-center w-100 p-3">
      <div className="position-relative d-flex w-50  bg-dark rounded-pill shadow-sm">
        {/* Highlighted background */}
        <motion.div
          className="position-absolute w-50 h-100 bg-white rounded-pill"
          layout
          transition={{ type: 'spring', stiffness: 300, damping: 30 }}
          initial={false}
          style={{ left: selected === 'editor' ? 0 : '50%' }}
        />

        {/* Options */}
        <div
          className={`position-relative z-10 flex-fill text-center py-2 fw-bold cursor-pointer ${selected === 'editor' ? 'text-dark' : 'text-white'
            }`}
          style={{ cursor: 'pointer' }}
          onClick={() => handleToggle('editor')}
        >
          Editor
        </div>
        <div
          className={`position-relative z-10 flex-fill text-center py-2 fw-bold cursor-pointer ${selected === 'publish' ? 'text-dark' : 'text-white'
            }`}
          style={{ cursor: 'pointer' }}
          onClick={() => handleToggle('publish')}
        >
          Publish
        </div>
      </div>
    </div>
  );
};

export default Toggle;
