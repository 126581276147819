/** @format */

import styled from 'styled-components';
import { Container } from '../../Style/CommonStyle.t3';

export const CitiesContainer = styled(Container)`
  width: min(95rem, 95%);
  .cities_header {
    font-family: "Tenor Sans", sans-serif;
    font-weight:bold;
    margin-left: 10px;
    margin-top: 3rem;
  margin-bottom: 1rem;
  }
  .custom_arrows_prev,
  .custom_arrows_next {
    background-color: ${({ theme: { colors } }) => colors.primary};
    padding: 1rem;
    border-radius: 100%;
    cursor: pointer;
  }
`;
