/** @format */

import { useEffect, useState } from "react";
import { BlogSliderContainer } from "./blog_slider.style";

import BlogCardT3 from "../blogcard/blogcard.component";

import axios from "axios";
import Cookies from "js-cookie";
import Slider from "react-slick";
export default function BlogSlider() {
  const [data, setData] = useState([]);
  const BlogSliderSettings = {
    infinite: true,
    variableWidth: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: true,
    swipe: true,
    touchMove: true
  };
  useEffect(() => {
    const FetchData = async () => {
      const token = Cookies.get("token");
      const agentUrl = window.location.href.split("/")[2];

      let config = {
        headers: {},
        params: {},
      };

      if (token) {
        config.headers["x-access-token"] = token;
      } else {
        config.params["domain"] = agentUrl;
      }

      try {
        const fetchData = await axios.get("/api/get-agent-blog", config);
        const data = await fetchData.data.data;
        setData(data);
      } catch (e) {
        throw new Error(e);
      }
    };
    FetchData();
  }, []);
  return (
    <BlogSliderContainer>
      <div className="header_container">
        <p className="heading" >Blogs</p>
      </div>
      <Slider {...BlogSliderSettings}>
        {data.map((data) => (
          <BlogCardT3 data={data} key={data.title} />
        ))}
      </Slider>
    </BlogSliderContainer>
  );
}
