import { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { useLiveEditorHighLighter } from '../../../../context/liveEditorHighighlighter.context';
import LiveEdithTextHighLighter from '../../../../liveEditHighlighterstyles/liveEdithTextHighLighter.component';
import ClientReviewCard from './client_review_card/client_review_card.component';
import { ClientReviewContainer } from './client_reviews.style';
export default function ClientReview({data}) {
    const SliderRef = useRef(null);
    const { state } = useLiveEditorHighLighter()
    useEffect(() => {
        const SlideNumber = state.type.split('-')
        if (SlideNumber.length > 1) {
            if (SliderRef.current) {
                SliderRef?.current?.slickGoTo(Number(SlideNumber[1]))
            }
        }
    }, [state.type])
    const ClientReviewSetting = {
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            }
        ],
    };
  return (
      <ClientReviewContainer id='sec5'>
          <div className="header_container">
              <p className='heading'  style={{fontFamily:"Tenor Sans, sans-serif"}}>  <LiveEdithTextHighLighter section='sec5' type='text'>
                  {data.text}
              </LiveEdithTextHighLighter></p>
              <p className="sub_heading" style={{fontFamily:"Tenor Sans, sans-serif"}}><LiveEdithTextHighLighter section='sec5' type='description' >
                  {data.description}
              </LiveEdithTextHighLighter></p>
          </div>
          <Slider {...ClientReviewSetting} className='ClientReviewSlider' ref={SliderRef}>
              {data.cards.map((data, i) => (
                  <ClientReviewCard key={i} data={data} index={i} />
              ))}
          </Slider>
      </ClientReviewContainer>
  )
}
