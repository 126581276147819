import { motion } from "framer-motion";
import { styled } from "styled-components";


export const EmailContainer = styled.div`
   position: fixed;
   top: 50%;
   left:50%;
   border: 1px solid black;
   border-radius:12px;
   transform: translate(-50%, -50%);
   background:white;
   padding:1.5rem;
   
  .editor_class{
    min-height:20rem;
    overflow:scroll;
    max-height:25rem;
   } 
  .action_button{
    display:flex;
    justify-content: space-between;
    align-items:center;
    background-color: #f0f0f0;
    border-radius: 10px;
    padding-block:1rem;
    padding-inline:0.75rem;
    
    .email_action {
       display:flex;
       justify-content:cetner;
       align-items:center;
       gap:0.40rem;
      select {
       background-color: white;
       border: 1px solid black;
       padding: 10px 15px;
       font-size: 18px;
      }
     }
  } 
`

export const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  padding-block:1rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 10px;
  margin-bottom:1rem;
`;

export const SlideText = styled(motion.div)`
  font-size: 19px;
  font-weight: bold;
  color: #333;
  text-align: center;
`;

type ButtonProps = {
  $type: 'action' | 'send'
}

export const Button = styled.button<ButtonProps>`
  position:  ${({ $type }) => $type === 'action' ? 'absolute' : 'static'};
  top:  ${({ $type }) => $type === 'action' ? '50%' : ''};
  transform: ${({ $type }) => $type === 'action' ? 'translateY(-50%)' : 'translateY(0%)'} ;
  background-color: black;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: white;
    color: black;
  }

  &:first-of-type {
    left: 10px;
  }

  &:last-of-type {
    right: 10px;
  }
`;

export const EditorBoxContainer = styled(motion.div)`
`

export const MessageBoxContainer = styled(motion.div)`
  min-width:15rem;
  min-height:15rem;
  display:flex;
  justify-content:center;
  align-items:center;
  gap:0.35rem;
  flex-direction:column; 
`
