/** @format */

import { useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { useLiveEditorHighLighter } from '../../../context/liveEditorHighighlighter.context';
import LiveEdithTextHighLighter from '../../../liveEditHighlighterstyles/liveEdithTextHighLighter.component';
import LiveEditImageHighLighter from '../../../liveEditHighlighterstyles/liveEditImageHighLighter.component';
import { City, SliderContainer } from './CityCard.style';

export default function CityCardContainer({
  data,
  CustomArrowsNext,
  CustomArrowsPrev,
  type,
}) {
  const { state } = useLiveEditorHighLighter();

  const SliderRef = useRef(null);
  useEffect(() => {
    const SlideNumber = state.type.split('-');
    if (SlideNumber.length > 1) {
      if (SliderRef.current) {
        SliderRef?.current?.slickGoTo(Number(SlideNumber[1]));
      }
    }
  }, [state.type]);
  useEffect(() => {
    if (SliderRef.current) {
      SliderRef.current.slickGoTo(data.length - 1);
    }
  }, [data.length]);
  const CitiesSetting = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: Math.min(3, data.length),
    slidesToScroll: 1,
    nextArrow: <CustomArrowsNext />,
    prevArrow: <CustomArrowsPrev />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: Math.min(2, data.length),
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: Math.min(1, data.length),
        },
      },
    ],
  };
  return (
    <SliderContainer>
      <Slider
        {...CitiesSetting}
        className="slider"
        ref={SliderRef}>
        {data.map((data, index) => (
          <div
            className="city_card"
            key={data.text}>
            <City to={`../idxlisting/?city=${data.text}`}>
              <p className="city_name" >
                <LiveEdithTextHighLighter
                  section={type}
                  type={`text-${index}`}>
                  {data.text}
                </LiveEdithTextHighLighter>
              </p>
              <div className="city_image">
                <LiveEditImageHighLighter
                  key={data.text}
                  section={type}
                  type={`image${index}`}>
                  <img
                    src={`/public/${data.image}`}
                    alt="cityImages"
                  />
                </LiveEditImageHighLighter>
              </div>
            </City>
          </div>
        ))}
      </Slider>
    </SliderContainer>
  );
}
