import React from 'react'
import LiveEdithTextHighLighter from '../../../../liveEditHighlighterstyles/liveEdithTextHighLighter.component'
import LiveEditImageHighLighter from '../../../../liveEditHighlighterstyles/liveEditImageHighLighter.component'
import { isImage, isVideo } from '../../../../utils/Helper_functions/VideoAndImage'
import VideoPlayer from '../../../utilComponent/VideoComponent/VideoPlayer.utilcomponent'
import { Section2Container } from './section2.style'

export default function Section2({ data }) {
  return (
    <Section2Container
      id="sec4">
      <div className='Left_section'>
        <LiveEditImageHighLighter
          section="sec2"
          type="img ">
          <VideoPlayer data={data.img} aspectRatio={'1107/749'} />
        </LiveEditImageHighLighter>
      </div>

      <div className="Right_section slideInLeft">
        <h2 className='heading' style={{ color: data.color, fontFamily: 'Tenor Sans, sans-serif' }}>
          <LiveEdithTextHighLighter
            section="sec2"
            type="text">
            {data?.text}
          </LiveEdithTextHighLighter>
        </h2>
        <LiveEdithTextHighLighter
          section="sec2"
          type="description">
          <div dangerouslySetInnerHTML={{ __html: data.description }} style={{ color: data.color, fontFamily: 'Tenor Sans, sans-serif' }}></div>
        </LiveEdithTextHighLighter>
        <br />
        <LiveEdithTextHighLighter
          section="sec2"
          type="description2">
          <div dangerouslySetInnerHTML={{ __html: data.description2 }} style={{ color: data.color, fontFamily: 'Tenor Sans, sans-serif' }}></div>
        </LiveEdithTextHighLighter>
      </div>
    </Section2Container>
  )
}
