/** @format */

import styled from 'styled-components';

export const BlogCardContainer = styled.div`
.leanblog-card {
  width: 240px;
  height: 480px;
  transition: all 0.5s ease-in-out;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  margin: 10px 7px;
}

.leanblog-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  pointer-events: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: linear-gradient(
    11deg,
    rgba(19, 24, 38, 0.7) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

.leanblog-card:hover::before {
  background: linear-gradient(360deg, #131826 0%, rgba(19, 24, 38, 0) 100%);
}

.leanblog-card:hover {
  z-index: 1;
  width: 500px;
  transition: all 0.7s ease-in-out;
}

.leanblog-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.leanblog-card .leanblog-card-content {
  padding: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  vertical-align: baseline;
}

.leanblog-card:hover .leanblog-card-content {
  padding: 15px;
}

.leanblog-card .leanblog-card-content h4 {
  font-family: "Tenor Sans", sans-serif;
  font-size: 24px;
  font-weight: 800;
  letter-spacing: normal;
  line-height: 1.31;
  -webkit-transition: font-size 0.6s;
  transition: font-size 0.6s;
  margin-bottom: 4px;
  color: #ffffff;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.leanblog-card:hover .leanblog-card-content h4 {
  font-size: 48px;
  line-height: 59.5px;
  transition: all 0.7s ease-in-out;
}

.leanblog-card .leanblog-card-content > p {
  font-size: 11px;
  font-weight: 500;
  line-height: 1.33;
  // text-transform: uppercase;
  color: #ffffff;
}

.leanblog-card:hover .leanblog-card-content > p {
  font-size: 13px;
}

.leanblog-card .leanblog-card-content .item-footer {
  max-height: 0;
  opacity: 0;
  transition: all 0.7s ease-in-out;
}

.leanblog-card:hover .item-footer {
  max-height: 500px;
  opacity: 1;
  margin-top: 30px;
}

.leanblog-card:hover .leanblog-card-content .item-footer > p {
  color: #ffffff;
  font-size: 16px;
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  pointer-events: none;
  max-width: 400px;
}

.leanblog-card:hover .leanblog-card-content .item-footer > h6 {
  color: #ffffff;
  /* font-size: 16px; */
  margin: 0;
  pointer-events: none;
  max-width: 400px;
}

`;
