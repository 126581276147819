import styled from "styled-components";


export const FooterContainer = styled.div`
      /* margin-top: -4rem; */
`
export const ImageRedirector = styled.div`
 margin-block: 3rem;
`

export const SubscribeContainer1 = styled.div`
  background-image: url(https://images.pexels.com/photos/161758/governor-s-mansion-montgomery-alabama-grand-staircase-161758.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 5rem 3rem;
  position: relative;
        font-family: "Tenor Sans", sans-serif;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }
  h1 {
    color: #fff;
    text-transform: uppercase;
    font-weight:bold;

  }
  p {
    color: #bbbbbb;
  }
  /* Container styling */
  .form-group {
    position: relative;
    margin-bottom: 2rem;
  }

  /* Base styling for Material UI-like underline */
  .material-input,
  .material-select {
    font-size: 16px;
    padding: 8px 0;
    border: none;
    border-bottom: 2px solid #bbbbbb;
    outline: none;
    width: 100%;
    transition: border-color 0.3s ease;
    background-color: transparent;
    color: #fff;
  }

  /* Focus effect */
  .material-input:focus,
  .material-select:focus {
    border-color: #fff; /* Material UI primary color */
  }

  /* Floating label style */
  .form-label {
    position: absolute;
    top: -10px;
    font-size: 14px;
    color: #ccc;
    transition: color 0.3s ease;
  }

  /* Hover effect for select */
  .material-select:hover {
    cursor: pointer;
  }
`;

export const SubscribeContainer = styled.div`
  background-image: url(https://images.pexels.com/photos/29071945/pexels-photo-29071945/free-photo-of-elegant-vintage-dining-room-with-candlelight-ambiance.jpeg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 5rem 3rem;
  position: relative;
font-weight:bold;
font-family: "Tenor Sans", sans-serif;  
  
  
  
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }
  h1 {
    color: #fff;
    text-transform: uppercase;
    font-weight:bold;

  }
  p {
    color: #bbbbbb;
  }
  /* Container styling */
  .form-group {
    position: relative;
    margin-bottom: 2rem;
  }

  /* Base styling for Material UI-like underline */
  .material-input,
  .material-select {
    font-size: 16px;
    padding: 8px 0;
    border: none;
    border-bottom: 2px solid #bbbbbb;
    outline: none;
    width: 100%;
    transition: border-color 0.3s ease;
    background-color: transparent;
    color: #fff;
  }

  /* Focus effect */
  .material-input:focus,
  .material-select:focus {
    border-color: #fff; /* Material UI primary color */
  }

  /* Floating label style */
  .form-label {
    position: absolute;
    top: -10px;
    font-size: 14px;
    color: #ccc;
    transition: color 0.3s ease;
  }

  /* Hover effect for select */
  .material-select:hover {
    cursor: pointer;
  }
`;

export const SubscribeContainer3 = styled.div`
  background-image: url(https://images.pexels.com/photos/68389/pexels-photo-68389.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 5rem 3rem;
  position: relative;
  font-family: "Tenor Sans", sans-serif;
  font-weight:bold;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }
  h1 {
    color: #fff;
    text-transform: uppercase;
    font-weight:bold;

  }
  p {
    color: #bbbbbb;
  }
  /* Container styling */
  .form-group {
    position: relative;
    margin-bottom: 2rem;
  }

  /* Base styling for Material UI-like underline */
  .material-input,
  .material-select {
    font-size: 16px;
    padding: 8px 0;
    border: none;
    border-bottom: 2px solid #bbbbbb;
    outline: none;
    width: 100%;
    transition: border-color 0.3s ease;
    background-color: transparent;
    color: #fff;
  }

  /* Focus effect */
  .material-input:focus,
  .material-select:focus {
    border-color: #fff; /* Material UI primary color */
  }

  /* Floating label style */
  .form-label {
    position: absolute;
    top: -10px;
    font-size: 14px;
    color: #ccc;
    transition: color 0.3s ease;
  }

  /* Hover effect for select */
  .material-select:hover {
    cursor: pointer;
  }
`;