/** @format */

import styled from "styled-components";
import { transparentize } from "polished";

type ContainerProps = {
  $bg: string;
};

export const Container = styled.div<ContainerProps>`
  width: min(95.25rem, 90%);
  min-height: 25.5rem;
  border-radius: 1.25rem;
  margin: 4rem auto 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  background-image: url(${(props) => props.$bg});
  background-position: center;
  background-size: cover;
  background-position: center;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme: { colors } }) =>
      transparentize(0.2, colors.primary)};
    border-radius: 1.25rem;
  }
  .dot_grid {
    position: absolute;
    top: -10%;
    right: 0;
    rect {
      fill: ${({ theme: { colors } }) => colors.text};
    }
  }
  .circle_svg {
    position: absolute;
    z-index: -1;
    bottom: -19%;
    left: -3rem;
    path {
      fill: ${({ theme: { colors } }) => colors.primary};
    }
  }
  .header_container {
    margin-bottom: 0.65rem;
    .header {
      font-family: "Tenor Sans", sans-serif;
      font-size: clamp(2rem, 3vw, 3rem);
      font-weight: bold;
      line-height: 4.4463rem;
      text-align: center;
      color: #fdfdfe;
      position: relative;
    }
    .sub_header {
      color: #fdfdfe;
      font-family: "Montserrat",sans-serif;
      font-size: 1.125rem;
      font-weight: 200;
      line-height: 1.3181rem;
      text-align: center;
      position: relative;
    }
  }
  .city_search_container {
    width: min(43.4375rem, 95%);
    margin-top: 3rem;
    margin-top: clamp(1rem, 3vw, 3rem);
    position: relative;
    min-height: 5.5rem;
  }
  @media screen and (max-width: 548px) {
    background-color: ${({ theme: { colors } }) =>
      transparentize(0.1, colors.primary)};
  }
`;

export const MainContainer = styled.div``;

export const SearchBox = styled.div`
  position: relative;
  z-index: 10;
  padding: 0.625rem 0.625rem 0.625rem 1.5625rem;
  gap: 0rem;
  border-radius: 0.9375rem;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.55rem;
  flex-wrap: wrap;
  .location {
    width: 25px;
    height: 25px;
  }
  .search_box {
    border: none;
    outline: none;
    flex: 0.7;
  }
  .search_button {
    padding: 0.9375rem 1.25rem 0.9375rem 1.25rem;
    border-radius: 0.625rem;
    font-family: "Montserrat",sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.3181rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    border: none;
    outline: none;
    background: ${({ theme: { colors } }) => colors.primary};
    color: ${({ theme: { colors } }) => colors.white};
    flex: 0.2;
  }

  .select_option {
    height: 100%;
    padding: 0.9375rem 1.25rem 0.9375rem 1.25rem;
    background-color: transparent;
    border: none;
    outline: none;
    margin-right: 0.35rem;
    flex: 0.1;
  }
  @media screen and (max-width: 548px) {
    .search_box {
      flex: 0.9;
    }
    .search_button {
      position: absolute;
      top: 110%;
      background-color: white;
      color: ${({ theme: { colors } }) => colors.primary};
      svg {
        path {
          fill: ${({ theme: { colors } }) => colors.primary};
        }
      }
    }
    /* background-color: transparent; */
  }
`;
