import React, { ChangeEvent, useEffect, useState } from 'react'
import { Button, EmailContainer, SliderContainer, SlideText, EditorBoxContainer, MessageBoxContainer } from './EmailTemplates.style'
import emailData from './email.json'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromHTML, ContentState, Modifier } from 'draft-js';
import { AnimatePresence } from 'framer-motion';
import useEmailRemider from './useEmailReminder';

type EmailTemplatesProps = {
  type: 'buyers' | 'sellers' | null
  email: string
  close: () => void
}

const EmailTemplates = ({ type = null, email, close }: EmailTemplatesProps) => {
  const [message, setMessage] = useState((): EditorState =>
    EditorState.createEmpty()
  );
  const [days, setDays] = useState<string>('')

  const [template, setTemplate] = useState<number>(0);

  const [index, setIndex] = useState<number>(0);

  const [Emailmessage, setEmailMessage] = useState<string | null>(null)

  const { SetRemider } = useEmailRemider({ type, setEmailMessage });

  const handleStateChange = (Data: EditorState): void => {
    setMessage(message)
  }

  const nextSlide = (): void => {
    setTemplate((prevIndex: number): number => (prevIndex + 1) % emailData[type].length);
  };

  const prevSlide = (): void => {
    setTemplate((prevIndex: number): number => (prevIndex - 1 + emailData[type].length) % emailData[type].length);
  };

  useEffect(() => {
    if (type) {
      const blocksFromHTML = convertFromHTML(emailData[type][template].Message)
      const contenState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      )
      const NewEditorState = EditorState.createWithContent(contenState);
      setMessage(NewEditorState)
    }
  }, [template])

  return (
    <EmailContainer>
      <AnimatePresence mode="wait">
        {
          Emailmessage === null ?
            <EditorBoxContainer
              key={"mail_container"}
              initial={{ opacity: 0, x: 100, height: 0, width: 0 }}
              animate={{ opacity: 1, x: 0, height: "auto", width: "auto" }}
              exit={{ opacity: 0, x: -100, height: 0, width: 0 }}
              transition={{ duration: 0.2 }}
            >
              <SliderContainer>
                <AnimatePresence mode="wait">
                  <SlideText
                    key={emailData[type][template].label}
                    initial={{ opacity: 0, x: 100 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -100 }}
                    transition={{ duration: 0.2 }}
                  >
                    {emailData[type][template].label}
                  </SlideText>
                </AnimatePresence>

                <Button $type='action' onClick={prevSlide}>Prev</Button>
                <Button $type='action' onClick={nextSlide}>Next</Button>
              </SliderContainer>
              <Editor
                onEditorStateChange={handleStateChange}
                editorClassName="editor_class"
                editorState={message}
              />
              <div className='action_button'>
                <div className='email_action'>
                  <Button $type='send' onClick={(): Promise<void> => SetRemider(message, email, days)}> Send Email </Button>
                  <select value={days} onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => setDays(e.target.value.toString())}>
                    <option value="">select</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                  </select>
                  <p>Days</p>
                </div>
                <Button $type='send' onClick={close}> Close </Button>
              </div>
            </EditorBoxContainer>
            :
            <MessageBoxContainer key={"error_container"}>
              <p>{Emailmessage}</p>
              <Button $type='send' onClick={() => setEmailMessage(null)} >Close</Button>
            </MessageBoxContainer>
        }
      </AnimatePresence>
    </EmailContainer>
  )
}

export default EmailTemplates
